import { useLocation, useParams } from 'react-router';
import * as QueryString from '../../utils/queryString';
import {
  derivePlayerDimensionFromSearchParams,
  derivePlayerDimensionOptionFromSearchParams
} from '../../selectors/playerDataSelector';
import { sanitizeParams } from '../../utils/RoutingHelpers';
import { useSelector } from 'react-redux';
import config from '../../config';
import { TAppState } from '../../store';
import React, { RefObject, useCallback, useEffect, useState } from 'react';
import { fetchProduct } from '../../actions/product';
import { Nullable } from '../../utils/types';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '../../components/Breadcrumbs';
import BreadcrumbHelpers from '../../utils/BreadcrumbHelpers';
import Typography from '@material-ui/core/Typography';
import ExternalLink from '../../components/ExternalLink';
import EditIcon from '@material-ui/icons/Edit';
import PageWrapper from '../../components/PageWrapper';
import { Box, makeStyles, Theme } from '@material-ui/core';
import DimensionSelector from './DimensionSelector';
import Markdown from '../../components/Markdown';
import { TProductDetails } from '../../api/product';
import deriveDimensionsFromAxis from '../../utils/deriveDimensionsFromAxis';
import updateUrlSearchParams from '../../utils/updateUrlSearchParams';
import { CycloneDimensionOverview, TProductAxis } from '../../api/axis';
import Api from '../../api';
import PlayerWrapper from './PlayerWrapper';
import { PlayerDimension, PlayerDimensionOption } from '../../components/Player';
import { debounce, noop } from 'lodash';
import getProductDataItemFromStore from '../../utils/getProductDataItemFromStore';
import imageSize from '../../utils/imageSize';
import * as StoreIndex from '../../utils/storeIndex';
import { unstable_batchedUpdates } from 'react-dom';
import useAppDispatch from '../../hooks/useAppDispatch';
import SinglePointViewerWrapper from './SignlePointViewerWrapper';
import PageLoading from '../../components/PageLoading';
import PageError from '../../components/PageError';

type TRouteParams = {
  packageName: string,
  name: string
};

export type DimensionOption = {
  value: string | number,
  label: string
}

export type Dimension = DimensionChoice | DimensionLocation | DimensionCyclone | DimensionMultipleChoice;
export enum DimensionType {
  MULTIPLE_CHOICE = 'multiple-choice',
  CHOICE = 'choice',
  LOCATION = 'location',
  CYCLONE= 'cyclone'
}

export type DimensionBase<T extends {}> = {
  name: string;
  label: string;
  dynamic: boolean;
} & T;

export type DimensionChoice = DimensionBase<{
  type: DimensionType.CHOICE;
  animation: boolean;
  values: Array<DimensionOption>;
}>

export type DimensionLocation = DimensionBase<{
  type: DimensionType.LOCATION;
}>

export type DimensionCyclone = DimensionBase<{
  type: DimensionType.CYCLONE,
  values: Array<DimensionOption>;
  overview: CycloneDimensionOverview;
}>

export type DimensionMultipleChoice = DimensionBase<{
  type: DimensionType.MULTIPLE_CHOICE;
  values: Array<{
    value: string;
    label: string;
    status: "disabled" | "enabled";
  }>
}>

export type DimensionSelectionMapping = {
  [dimensionName: string]: DimensionOption['value']
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  info: {
    marginBottom: theme.spacing(3)
  },
  adminLink: {
    marginLeft: '0.5em'
  },
  subtitle: {
    color: theme.palette.text.secondary
  },
  contentWrapper: {
    width: '100%'
  },
  description: {
    marginTop: theme.spacing(4)
  }
}));

export const getAxis = async (product: TProductDetails, params: TProductDetails['values']): Promise<Array<TProductAxis>> => {
  const anyDynamic = anyDynamicDimensions(product.axis);

  if (anyDynamic) {
    // @ts-ignore
    const staticDimensionValues = deriveDimensionsFromAxis(product.axis, params, false);

    return Api.Axis.list(
      product.package.name,
      product.name,
      staticDimensionValues.selected
    );
  } else {
    return Promise.resolve(product.axis);
  }
};

const anyDynamicDimensions = (axes: Array<{ dynamic?: boolean }>) => {
  return axes.some(axis => axis.dynamic);
};

export const usePlayerWrapperHeight = (
  product: TProductDetails,
  selectedDimensions: DimensionSelectionMapping,
  $element: RefObject<HTMLElement>
) => {
  const [height, setHeight] = useState<string | number>('75vh');

  const setPlayerWrapperHeight = async () => {
    if (!product || !selectedDimensions || !$element.current) {
      return Promise.reject();
    }

    const packageName = product.package.name;

    const item = getProductDataItemFromStore(packageName, product.name, selectedDimensions);

    if (!item.url) {
      return Promise.reject();
    }

    const { width, height } = await imageSize(item.url);

    const imageAspectRatio = width / height;
    const isVertical = imageAspectRatio < 1;

    const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

    const maxHeight = (isVertical ? 1.05 : 0.8) * viewportHeight;

    const playerWrapperWidth = $element.current.getBoundingClientRect().width;
    const playerWrapperHeight = Math.min(maxHeight, (playerWrapperWidth * height / width) + 52);

    if (!playerWrapperHeight && playerWrapperHeight === height) {
      return Promise.reject();
    }

    setHeight(playerWrapperHeight);
  };

  const debouncedPlayerHeightUpdate = useCallback(
    debounce(() => {
      setPlayerWrapperHeight().catch(noop);
    }, 200),
    []
  );

  const onWindowResize = () => debouncedPlayerHeightUpdate();

  useEffect(() => {
    setPlayerWrapperHeight().catch(noop);

    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  return height;
};

const Product = () => {
  const params = useParams<TRouteParams>();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const searchParams = sanitizeParams(QueryString.parse(location.search));
  const productName = params.name;
  const packageName = config.rootPackageId ? config.rootPackageId : params!.packageName;

  const product = useSelector((state: TAppState) =>
    state.productDetails[StoreIndex.productDetails(packageName, productName)]?.data
  );

  const [error, setError] = useState<Nullable<string>>(null);
  const [dimensions, setDimensions] = useState<Nullable<Array<Dimension>>>(null);
  const [dimensionValues, setDimensionValues] = useState<Nullable<DimensionSelectionMapping>>(null);
  const [playerSelectedDimension, setPlayerSelectedDimension] = useState<Nullable<PlayerDimension>>(null);
  const [playerSelectedDimensionOption, setPlayerSelectedDimensionOption] =
    useState<Nullable<PlayerDimensionOption>>(null);
  const [isReadyToRender, setIsReadyToRender] = useState<boolean>(false);

  const updateDimensions = useCallback((values: TProductDetails['values']) => {
    updateUrlSearchParams(values, 'replace');
  }, [packageName, productName]);

  const onDimensionChange = (values: TProductDetails['values']) => {
    if (!product || !dimensions || !dimensionValues) {
      return;
    }

    getAxis(product, values).then(axis => {
      // @ts-ignore
      const { dimensions, selected } = deriveDimensionsFromAxis(axis, values);

      unstable_batchedUpdates(() => {
        // @ts-ignore
        const playerSelectedDimension = derivePlayerDimensionFromSearchParams(dimensions, values);
        setPlayerSelectedDimension(playerSelectedDimension ?? null);

        setDimensions(dimensions);
        setDimensionValues(selected);
      });
    });
  };

  const onPlayerDimensionChange = (dimension: Dimension) => {
    if (!dimensions) {
      return;
    }

    const params = {
      player_dimension: dimension.name
    };

    const playerSelectedDimension = derivePlayerDimensionFromSearchParams(dimensions, params);
    setPlayerSelectedDimension(playerSelectedDimension ?? null);
  };

  const onPlayerDimensionOptionChange = (option: PlayerDimensionOption) => {
    if (!playerSelectedDimension) {
      return;
    }

    const params = {
      [playerSelectedDimension.name]: option.value
    };

    setDimensionValues({
      ...dimensionValues,
      ...params
    });
  };

  useEffect(() => {
    dispatch(fetchProduct(packageName, productName)).then(product => {
      getAxis(product, searchParams).then(axis => {
        const { dimensions, selected } = deriveDimensionsFromAxis(axis, searchParams);

        const playerSelectedDimension = derivePlayerDimensionFromSearchParams(dimensions, searchParams);

        setDimensions(dimensions);
        setDimensionValues(selected);
        setPlayerSelectedDimension(playerSelectedDimension ?? null);
        setIsReadyToRender(true);
      });
    }).catch((error: any) => {
      setError('Product unavailable');
    });
  }, []);

  useEffect(() => {
    if (dimensionValues) {
      const playerSelectedDimensionOption = playerSelectedDimension &&
      // @ts-ignore
        derivePlayerDimensionOptionFromSearchParams(playerSelectedDimension, dimensionValues);

      setPlayerSelectedDimensionOption(playerSelectedDimensionOption ?? null);
    }
  }, [dimensions, dimensionValues, playerSelectedDimension]);

  useEffect(() => {
    updateDimensions(dimensionValues || {});
  }, [dimensionValues]);

  if (error) {
    return (
      <PageError title="We're sorry. Product is currently unavailable." />
    );
  }

  if (
    !product ||
    !dimensions ||
    !dimensionValues ||
    !isReadyToRender
  ) {
    return <PageLoading />;
  }

  return (
    <PageWrapper>
      <Grid container direction="column" className={classes.root}>
        <Grid item>
          <Breadcrumbs
            paths={BreadcrumbHelpers.buildPaths('product', product)}
          />
        </Grid>

        <Grid item className={classes.info}>
          <Typography variant="h2">
            {product.title}

            {
              product.admin &&
              <ExternalLink href={product.admin.url} className={classes.adminLink}>
                <EditIcon color="primary" />
              </ExternalLink>
            }
          </Typography>

          <Typography variant="body1" className={classes.subtitle}>
            {product.subtitle}
          </Typography>
        </Grid>

        <Grid item className={classes.contentWrapper}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={3} xl={2} style={{ paddingBottom: 0 }}>
              <DimensionSelector
                packageName={packageName}
                productName={product.name}
                dimensions={dimensions}
                selectedDimensions={dimensionValues}
                onChange={onDimensionChange}
              />
            </Grid>

            <Grid item xs={12} lg={9} xl={10}>
              <Box position="relative">
                {
                  playerSelectedDimension && playerSelectedDimensionOption ?
                    <PlayerWrapper
                      product={product}
                      dimensions={dimensions}
                      selectedDimensions={dimensionValues}
                      onSelectedDimensionsChange={onDimensionChange}
                      playerSelectedDimension={playerSelectedDimension}
                      playerSelectedDimensionOption={playerSelectedDimensionOption}
                      onPlayerDimensionChange={onPlayerDimensionChange}
                      onPlayerDimensionOptionChange={onPlayerDimensionOptionChange}
                    />
                    :
                    <SinglePointViewerWrapper
                      product={product}
                      selectedDimensions={dimensionValues}
                    />
                }
              </Box>

              <Box className={classes.description}>
                <Markdown source={product.description} />
              </Box>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </PageWrapper>
  );
};

export default Product;
