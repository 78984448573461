import { Typography, Link, Box, Divider, withStyles } from '@material-ui/core';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';

interface IProps {
  source: string;
  removeParagraphSpacing?: boolean;
  classes: {
    heading: string;
    paragraph: string;
    paragraphNoSpacing: string;
    divider: string;
  }
}

const Markdown = (props: IProps) => {
  const { source, classes, removeParagraphSpacing } = props;

  return (
    <ReactMarkdown
      source={source}
      linkTarget="_blank"
      renderers={{
        root: Box,
        heading: (props) =>
          <Typography {...props} variant={`h${props.level}`} className={classes.heading} />,
        paragraph: (props) =>
          <Typography
            {...props}
            variant="body2"
            color="inherit"
            className={classNames(classes.paragraph, removeParagraphSpacing && classes.paragraphNoSpacing)}
          />,
        link: (props) => <Link {...props} color="inherit" />,
        thematicBreak: (props) =>
          <Divider {...props} variant="horizontal" className={classes.divider} />
      }}
    />
  );
};

export default withStyles({
  heading: {
    marginTop: '1em',
    marginBottom: '1em'
  },
  paragraph: {
    marginBottom: '1em'
  },
  paragraphNoSpacing: {
    marginBottom: 0
  },
  divider: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)'
  }
})(Markdown);
