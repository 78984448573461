import { withStyles } from '@material-ui/core';
import { ComponentType } from 'react';
import { flow, intersection } from 'lodash';
import { connect } from 'react-redux';
import styles from './styles';
import { DimensionProductData, PlayerDimension, TProps } from './index';
import { TAppState } from '../../store';
import {
  fetchProductData,
  preloadProductData, TProductDimensionParams
} from '../../actions/productData';
import { Dimension, DimensionSelectionMapping, DimensionType } from '../../containers/Product';
import * as StoreIndex from '../../utils/storeIndex';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connectThunkAction } from '../../utils/ReduxHelpers';

const filterDimensionValues = (dimensions: Array<Dimension>, productValues: DimensionSelectionMapping) => {
  const filteredParams: TProductDimensionParams = {};

  dimensions.forEach(dimension => {
    if (dimension.type === DimensionType.MULTIPLE_CHOICE) {
      const enabledValues = dimension.values
        .filter(i => i.status === 'enabled')
        .map(i => i.value);
      const values = productValues[dimension.name].toString().split(',');

      filteredParams[dimension.name] = intersection(values, enabledValues).join(',');
    } else {
      filteredParams[dimension.name] = productValues[dimension.name];
    }
  })

  return filteredParams;
}

export const derivePlayerDataFromProductData = (
  productData: TAppState['productData'],
  packageName: string,
  productName: string,
  dimensions: Array<Dimension>,
  selectedDimension: PlayerDimension,
  productValues: DimensionSelectionMapping
) => {
  const filteredProductValues = filterDimensionValues(dimensions, productValues);

  const data = selectedDimension.values.reduce((acc: DimensionProductData, dimensionValue) => {
    const key = StoreIndex.productData(packageName, productName, {
      ...filteredProductValues,
      [selectedDimension.name]: dimensionValue.value
    });

    const item = productData[key];

    if (item) {
      acc[dimensionValue.value] = {
        ...item,
        value: dimensionValue.value,
        label: dimensionValue.label
      };
    }

    return acc;
  }, {});

  return data;
};

const mapStateToProps = (state: TAppState, props: TProps) => {
  const { dimensions, selectedDimension, productValues, packageName, productName } = props;

  const data = derivePlayerDataFromProductData(
    state.productData,
    packageName,
    productName,
    dimensions,
    selectedDimension,
    productValues
  );

  return {
    ...props,
    dimensionProductData: data
  };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
  fetchProductData: connectThunkAction(dispatch, fetchProductData),
  preloadProductData: connectThunkAction(dispatch, preloadProductData)
});

export default (
  component: ComponentType<TProps>
): ComponentType<Omit<TProps, 'classes' | 'dimensionProductData' | 'fetchProductData' | 'preloadProductData'>> =>
  flow(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
  )(component);
