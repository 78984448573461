import { store } from '../store';
import * as StoreIndex from './storeIndex';
import { DimensionSelectionMapping } from '../containers/Product';

const getProductDataItemFromStore = (
  packageName: string,
  productName: string,
  selectedDimensions: DimensionSelectionMapping
) => {
  const { productData } = store.getState();

  const index = StoreIndex.productData(packageName, productName, selectedDimensions);
  return productData[index];
};

export default getProductDataItemFromStore;
